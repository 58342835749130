import {extendTheme} from '@chakra-ui/react';
import Color from 'color';

const primaryColor = '#22383E';
const secondaryColor = '#F2C94C';
const customColors = {
	primary: {
		50: Color(primaryColor).lighten(1.2).hex(),
		100: Color(primaryColor).lighten(0.8).hex(),
		200: Color(primaryColor).lighten(0.6).hex(),
		300: Color(primaryColor).lighten(0.4).hex(),
		400: Color(primaryColor).lighten(0.2).hex(),
		500: primaryColor,
		600: Color(primaryColor).darken(0.1).hex(),
		700: Color(primaryColor).darken(0.2).hex(),
		800: Color(primaryColor).darken(0.3).hex(),
		900: Color(primaryColor).darken(0.4).hex(),
	},
	secondary: {
		50: Color(secondaryColor).lighten(1.2).hex(),
		100: Color(secondaryColor).lighten(0.8).hex(),
		200: Color(secondaryColor).lighten(0.6).hex(),
		300: Color(secondaryColor).lighten(0.4).hex(),
		400: Color(secondaryColor).lighten(0.2).hex(),
		500: secondaryColor,
		600: Color(secondaryColor).darken(0.1).hex(),
		700: Color(secondaryColor).darken(0.2).hex(),
		800: Color(secondaryColor).darken(0.3).hex(),
		900: Color(secondaryColor).darken(0.4).hex(),
	},
};

const makeTheme = (globalStyles: Record<string, unknown> = {}) =>
	extendTheme({
		fonts: {
			heading: 'Inter',
			body: 'Inter',
		},
		components: {
			Code: {
				baseStyle: {
					px: 2,
					py: 1,
					borderRadius: 'lg',
				},
			},
		},
		colors: customColors,
		styles: {
			global: globalStyles,
		},
	});

export default makeTheme;
